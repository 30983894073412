import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Hero } from "../components";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Axios from 'axios';
import { Alerts } from "../components/Alerts";
import Spinner from "../components/Spinner";


const ContactPage = () => {
  const defaultValues = { fullName: '', email: '', message: '', firstName: '', lastName: '', phone: '' };
  const [contactInfo, setContactInfo] = useState(defaultValues);
  const [error, showError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChanges = (event) => {
    showError('');
    setSuccessMessage('');
    const { name, value } = event.target;

    if (name === 'firstName' || name === 'lastName') {
      const updatedName = name === 'firstName' ? 'firstName' : 'lastName';
      setContactInfo({
        ...contactInfo,
        [updatedName]: value,
        fullName: `${name === 'firstName' ? value : contactInfo.firstName} ${name === 'lastName' ? value : contactInfo.lastName}`
      });
    } else {
      setContactInfo({ ...contactInfo, [name]: value });
    }
  }

  const validateContactInfo = () => {
    const { firstName, lastName, email, message, phone } = contactInfo;

    if (!firstName || !lastName || !email || !message) {
      return 'First and last name, email and message fields are required. Phone number if optional';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address.';
    }

    if (phone) {
      if (!Number(phone) || phone.length > 15) {
        return 'Please enter a valid phone number.';
      }
    }

    return '';
  };

  const handleSubmit = async () => {
    const validationError = validateContactInfo();
    if (validationError.length) {
      showError(validationError);
      return;
    }

    setIsLoading(true);

    try {
      await Axios.post('contact', contactInfo);
      setContactInfo(defaultValues);
      setSuccessMessage('Your message has been submitted successfully! Our team will review and get back to you soon.');
    } catch (error) {
      showError('There was an issue submitting your message. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('');
      }, 6000)
    }
  }, [successMessage]);

  return (
    <main id="contact">
      <Hero>
        <motion.h1
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
          }}
          className="display-3 fw-bold"
        >
          Lets work <span className="text-primary">together</span>
        </motion.h1>

        <motion.h5
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
          }}
          className="my-4 fw-normal"
        >
          Get in touch with us today
        </motion.h5>
      </Hero>

      <section id="form-area">
        <Container className="py-5">
          <Row>
            <Col>
              <motion.h3
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  duration: 0.5,
                }}
                className="fw-bold"
              >
                Send us a message
              </motion.h3>
              <Form className="mt-5">
                <Alerts error={error} successMessage={successMessage} />
                <Row className="gy-3 row-md-cols-2">
                  <Form.Group as={Col} md="6">
                    <Form.Label>First name*</Form.Label>
                    <Form.Control type="text" name="firstName" onChange={handleChanges} value={contactInfo.firstName} placeholder="First name" />
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Last name*</Form.Label>
                    <Form.Control type="text" name="lastName" onChange={handleChanges} value={contactInfo.lastName} placeholder="Last name" />
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control type="email" name="email" onChange={handleChanges} value={contactInfo.email} placeholder="Enter email" />
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="phone"
                      name="phone"
                      onChange={handleChanges}
                      placeholder="Enter your phone number"
                      value={contactInfo.phone}
                    />
                  </Form.Group>
                </Row>

                <Row className="mt-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Message*</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="message"
                      onChange={handleChanges}
                      value={contactInfo.message}
                      placeholder="Message"
                    />
                  </Form.Group>
                </Row>

                <Row className="my-3">
                  <Form.Group tag={Col}>
                    <Button variant="dark" onClick={handleSubmit} disabled={isLoading} className="p-3 mt-4">
                      {isLoading ? 'Sending...' : 'Submit Message'}
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ContactPage;
