import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Hero } from "../components";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Axios from 'axios';

const CareersPage = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [error, showError] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    jobReference: "",
    file: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    showError('');
    setSuccessMessage('');

    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const removeFile = useCallback(() => { document.getElementById("fileInput").value = ""; }, []);

  const handleFileChange = (e) => {
    showError('');
    setSuccessMessage('');

    const contentTypes = [
      'application/pdf',
      'application/msword',
      'image/jpeg',
      'image/png'
    ];

    const file = e.target.files[0];

    if (!contentTypes.includes(file.type)) {
      setFormData((prev) => ({ ...prev, file: '' }));
      removeFile();
      return showError('Unsupported file was selected. Supported files: pdf, msword, jpeg, and png.');
    }

    setFormData((prev) => ({ ...prev, file }));
  };

  const handleSubmit = async (e) => {
    showError('');
    setSuccessMessage('');

    if (Object.values(formData).filter(v => v).length !== 4) {
      return showError('All fields are required.');
    }

    e.preventDefault();
    setIsSubmitting(true);
    const formDataToSend = new FormData();
    formDataToSend.append("fullName", formData.fullName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("jobReference", formData.jobReference);
    formDataToSend.append("file", formData.file);

    try {
      await Axios.post("/contact/resume", formDataToSend);
      setFormData({ fullName: "", email: "", jobReference: "", file: null });
      removeFile();
      setSuccessMessage('Your application has been submitted successfully! Our team will review and get back to you soon.')
    } catch (error) {
      showError("There was an issue submitting your application. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('');
      }, 6000)
    }
  }, [successMessage]);

  return (
    <main id="careers">
      {Boolean(error) && <div className="text-danger fs-6">{error}</div>}
      {Boolean(successMessage) && <div className="text-success fs-6">{successMessage}</div>}

      <Hero>
        <motion.h1
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
          }}
          className="display-2 fw-bold"
        >
          <span className="text-primary">Opportunity</span> created,
          <br />
          <span className="text-primary">potential</span> unleashed.
        </motion.h1>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
            delay: 0.5,
          }}
        >
          <Button
            className="p-3"
            variant="dark"
            onClick={() => navigate("/about")}
          >
            Learn more about us
          </Button>
        </motion.div>
      </Hero>

      <section id="form-area">
        <Container className="py-5">
          <Row>
            <Col>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <motion.h3
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.5, delay: 0.2, type: "spring", damping: 10, stiffness: 100 }}
                    className="fw-bold"
                  >
                    Join our team.
                  </motion.h3>
                )}
              </InView>

              <Form className="mt-4 pt-5" onSubmit={handleSubmit}>
                {Boolean(error) && <Alert variant="danger">{error}</Alert>}
                {Boolean(successMessage) && <Alert variant="success">{successMessage}</Alert>}
                <Row>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label>Full name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="fullName"
                        placeholder="Enter your full name"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label>Email*</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Job reference*</Form.Label>
                    <Form.Control
                      type="text"
                      name="jobReference"
                      placeholder="Enter job reference"
                      value={formData.jobReference}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Attach resume*</Form.Label>
                    <Form.Control
                      type="file"
                      id="fileInput"
                      name="resume"
                      onChange={handleFileChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Button type="submit" variant="dark" className="p-3 mt-4" disabled={isSubmitting}>
                      {isSubmitting ? "Submitting..." : "Submit application"}
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default CareersPage;
